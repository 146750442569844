@import 'src/styles/variables';

.logoFull {
  display: flex;
  width: 236px;

  @media (max-width: $lg) {
    display: none;
  }
}

.logoMobile {
  display: none;

  @media (max-width: $lg) {
    display: flex;
    width: 30px;
  }
}

.item {
  height: 70px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px 16px;
  border: 1px solid var(--palettes-blue-10);
  border-radius: 8px;

  background-color: var(--palettes-blue-10);

  transition: background-color 0.3s ease-out, border 0.3s ease-out;

  &:hover {
    border-color: var(--palettes-blue-40);

    background-color: var(--palettes-blue-20);
  }

  &:active {
    border-color: var(--palettes-blue-40);

    background-color: var(--palettes-blue-10);
  }
}

.iconBlock {
  display: flex;
  align-items: center;
  height: 40px;
  margin: auto;
  padding-right: 16px;
  border-right: 1px solid var(--palettes-blue-40);
}

.wrapper {
  padding-right: 20px;
}

.subtitle {
  color: var(--palettes-blue-100);
}

.content {
  margin-top: 4px;

  color: var(--palettes-gray-140) !important;
}

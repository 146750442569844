.wrapper {
  position: relative;

  margin-top: 4px;
}

.icon {
  position: relative;
  left: 3px;

  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.5;
  }
}

.badge {
  position: absolute;
  bottom: 12px;
  left: 13px;

  width: 22px;
  height: 18px;

  visibility: hidden;
  cursor: pointer;
}

.badgeVisible {
  visibility: visible;
}

@import 'src/styles/variables';

.wrapper {
  position: relative;
}

.picture {
  width: 100%;
  height: auto;
  border-radius: 16px;
  object-fit: cover;

  @media (max-width: $lg) {
    border-radius: 8px;
  }
  @media (max-width: $md) {
    height: 150px;
  }
}

.pictureHeader {
  height: 50px;
  border-radius: 0;
}

.nameplate {
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: var(--content);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 14px;

  border-radius: 8px 8px 0 0;

  color: var(--palettes-gray-180);
  font-size: 9px;

  background-color: var(--text-secondary);
  cursor: pointer;
  opacity: 0.8;
}

.nameplateHeader {
  bottom: 0;
}

.icon {
  margin-left: 2px;
}

.content {
  position: absolute;
  right: 0;
  bottom: -52px;
  z-index: var(--content);

  width: 268px;
  padding: 10px;

  border-radius: 4px;

  background-color: var(--text-secondary);
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
}

.overlay::after {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  content: '';
}

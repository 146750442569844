@import 'src/styles/variables';

.avatar {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 4px solid var(--palettes-white-100);
  border-radius: 50%;

  background: var(--palettes-gray-20);
  cursor: pointer;
  opacity: 1;

  transition: opacity 0.3s ease-out;

  &::before {
    position: absolute;

    width: 40px;
    height: 40px;
    border: 2px solid;
    border-radius: 50%;

    content: '';
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: $lg) {
    width: 28px;
    height: 28px;
    border: none;

    &::before {
      display: none;
    }
  }
}

.avatarPrimary {
  background: linear-gradient(180deg, var(--palettes-blue-140) 0%, var(--palettes-blue-80) 100%);

  &::before {
    border-color: var(--palettes-blue-140);
  }
}

.avatarSecondary {
  background: linear-gradient(180deg, var(--palettes-orange-100) 0%, var(--palettes-orange-60) 100%);

  &::before {
    border-color: var(--palettes-orange-60);
  }
}

.humanVector {
  position: absolute;
  top: 6px;

  @media (max-width: $lg) {
    top: 5px;
  }
}

.name {
  color: var(--palettes-white-100);
  text-transform: uppercase;

  @media (max-width: $lg) {
    font-size: 12px !important;
  }
}

.alert {
  position: absolute;
  top: -6px;
  right: -6px;

  display: flex;
  border: 1px solid var(--palettes-white-100);
  border-radius: 50%;

  background-color: var(--palettes-white-100);

  @media (max-width: $lg) {
    top: -4px;
    right: -4px;
  }
}

.notification {
  position: absolute;
  bottom: -10px;
  left: -10px;

  @media (max-width: $lg) {
    bottom: -6px;
    left: -6px;
  }
}

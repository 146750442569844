@import 'src/styles/variables';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--header);

  width: 100%;
  border-bottom: 1px solid var(--palettes-gray-10);

  background-color: var(--palettes-white-100);
}

.container {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: $fix-container-width;
  height: var(--default-header-height);
  margin: 0 auto;

  @media (max-width: $xl) {
    padding: 0 24px;
  }

  @media (max-width: $lg) {
    gap: 16px;
    height: var(--mobile-header-height);
  }
}

.wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $lg) {
    gap: 16px;
  }
}

.buttonMobile {
  display: none;

  @media (max-width: $lg) {
    display: flex;
  }
}

.button {
  @media (max-width: $lg) {
    display: none;
  }
}

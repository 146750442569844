.burger {
  display: flex;
}

.wrapper {
  position: relative;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--header);

  width: 300px;
  height: 100vh;

  background-color: var(--palettes-white-100);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10), 0px 16px 32px 0px rgba(0, 0, 0, 0.10);
  transform: translateX(-100%);

  transition: transform 0.3s ease-out;
}

.menuOpen {
  transform: translateX(0);
}

.list {
  padding: 12px 0 0;
  border-top: 1px solid var(--palettes-gray-20);

  list-style: none;
}

.menuItem {
  margin-bottom: 4px;
  padding: 12px 16px;

  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--palettes-blue-10);
  }

  &:active {
    background-color: var(--palettes-blue-20);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.closeWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 16px;
}

.root {
  --functional-button-height: 24px;
  --functional-button-color: var(--palettes-gray-140);
  --functional-button-color-hover: var(--text-primary);
  --functional-button-color-pressed: var(--palettes-gray-140);
  --functional-button-color-disabled: var(--palettes-gray-40);
  --functional-button-font-size: var(--palettes-gray-40);
  --functional-button-dark-theme-color:var(--palettes-gray-80) ;
  --functional-button-color-dark-theme-hover: var(--text-secondary);

  display: inline-flex;
  align-items: center;
  width: fit-content;
  margin: 0;
  padding: 0;
  border: 0;

  color: var(--functional-button-color);
  font-family: inherit;

  background-color: transparent;
  cursor: pointer;

  transition: 0.2s;

  &:hover {
    color: var(--functional-button-color-hover);
  }

  &:active {
    color: var(--functional-button-color-pressed);
  }

  &[disabled='true'],
  &:disabled {
    color: var(--functional-button-color-disabled);

    cursor: not-allowed;
  }
  &.dark {
    color: var(--functional-button-dark-theme-color);
    &:hover {
      color: var(--functional-button-color-dark-theme-hover);
    }

    &:active {
      color: var(--functional-button-color-pressed);
    }

    &[disabled='true'],
    &:disabled {
      color: var(--functional-button-color-disabled);

      cursor: not-allowed;
    }
  }
  &.darkActive {
    color: var(--functional-button-color-dark-theme-hover);
  }
  &.fullWidth {
    width: 100%;
  }
}

.text {
  font-weight: 500;
  font-size: 14px;

  font-family: inherit;

  font-style: normal;
  line-height: var(--functional-button-height);

  &.bodyM {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.iconRight {
  & .text {
    padding-inline-end: 4px;
  }
}

.iconLeft {
  & .text {
    padding-inline-start: 4px;
  }
}

.wrapperButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.fullWidth {
    width: 100%;
  }
}

.wrapperNotification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.notificationText {
  margin-right: 8px;
  margin-left: 8px;
}

.marker {
  padding-top: 2px;
  padding-left: 2px;
}

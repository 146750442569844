@import 'src/styles/variables';

.container {
  width: 100%;
}

.wrapper {
  display: flex;
  width: fit-content;

  @media (max-width: $lg) {
    display: none;
  }
}

.buttonMoreBlock {
  position: relative;

  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: var(--default-header-height);
  margin-bottom: -1px;
  padding: 0 8px;

  transition: background-color 0.3s ease-out;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 2px;

    background-color: var(--palettes-gray-190);
    transform: scaleX(0);

    transition: transform 0.2s ease-out;

    content: '';
  }

  &:hover {
    background-color: var(--palettes-gray-5);

    &::after {
      transform: scale(1);
    }
  }

  &:active {
    background-color: var(--palettes-gray-10);
  }
}

.chevron {
  transition: transform 0.3s ease-out;
}

.chevronOpen {
  transform: rotate(-180deg);
}

.menu {
  position: absolute;
  top: var(--default-header-height);
  left: calc(-50% - 40px);
  z-index: var(--header);

  width: 228px;
  padding: 8px;
  border-radius: 16px;

  list-style: none;
  background-color: var(--palettes-white-100);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10), 0px 16px 32px 0px rgba(0, 0, 0, 0.10);
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.3s ease-out;
}

.menuOpen {
  visibility: visible;
  opacity: 1;
}

.menuItem {
  margin-bottom: 4px;
  padding: 8px 16px;
  border-radius: 8px;

  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--palettes-gray-5);
  }

  &:active {
    background-color: var(--palettes-blue-10);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

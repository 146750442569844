@import 'src/styles/variables';

.wrapper {
  position: relative;

  @media (max-width: $lg) {
    display: none;
  }
}

.createButton {
  min-width: 95px;
}

.menu {
  position: absolute;
  top: var(--default-header-height);
  left: calc(-100% - 70px);
  z-index: var(--header);

  width: 380px;
  padding: 16px;
  border-radius: 16px;

  list-style: none;
  background-color: var(--palettes-white-100);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10), 0px 16px 32px 0px rgba(0, 0, 0, 0.10);
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.3s ease-out;
}

.menuOpen {
  visibility: visible;
  opacity: 1;
}

@import 'src/styles/variables';

.container {
  position: relative;
}

.dropdownOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--dropdown-overlay);

  width: 100vw;
  height: 100vh;

  visibility: hidden;
  opacity: 0;

  transition: opacity 0.3s ease-out;

  @media (max-width: $lg) {
    background-color: var(--palettes-gray-100);
    opacity: 0.4;
  }
}

.dropdownOverlayOpen {
  visibility: visible;
  opacity: 0.4;
}

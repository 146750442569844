@import 'src/styles/variables';

.wrapper {
  position: relative;
}

.menu {
  position: absolute;
  top: var(--default-header-height);
  left: calc(100% - 232px);
  z-index: var(--header);

  width: 256px;
  border-radius: 16px;
  overflow: hidden;

  background-color: var(--palettes-white-100);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10), 0px 16px 32px 0px rgba(0, 0, 0, 0.10);
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.3s ease-out;

  @media (max-width: $lg) {
    top: -8px;
    right: 0;

    height: 100vh;
    border-radius: 0;

    transform: translateX(100%);
    visibility: visible;
    opacity: 1;

    transition: transform 0.3s ease-out;
  }
}

.menuOpen {
  visibility: visible;
  opacity: 1;

  @media (max-width: $lg) {
    transform: translateX(0);
  }
}

.userBlock {
  width: 100%;
  padding: 16px;

  background-color: var(--palettes-blue-10);
}

.userBlockOrange {
  background-color: var(--palettes-orange-10);
}

.nameWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.name {
  overflow-x: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.role {
  color: var(--palettes-gray-100);
}

.roleOrange {
  color: var(--palettes-orange-100);
}

.logout {
  width: 100%;
  padding: 12px 8px;
  border-top: 1px solid var(--palettes-gray-20);

  @media (max-width: $lg) {
    padding: 12px 0;
  }
}

.list {
  margin: 0;
  padding: 12px 8px;

  list-style: none;

  @media (max-width: $lg) {
    padding: 12px 0;
  }
}

.hidden {
  display: none;
}

.listModerator {
  border-bottom: 1px solid var(--palettes-gray-20);
}

.action {
  width: 100%;
  padding: 6px 16px;
  border-radius: 8px;

  color: var(--palettes-gray-180);

  transition: background-color 0.3s ease-out;

  & span {
    font-weight: 400;
  }

  & div {
    gap: 8px;
  }

  &:hover {
    background-color: var(--palettes-gray-5);
  }

  @media (max-width: $lg) {
    border-radius: 0;

    &:hover {
      background-color: var(--palettes-blue-10);
    }

    &:active {
      color: var(--palettes-gray-180);

      background-color: var(--palettes-blue-20);
    }
  }
}

.buttonClose {
  display: none;

  @media (max-width: $lg) {
    display: block;
  }
}

.alert {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
  padding: 8px 16px;

  background-color: var(--palettes-red-10);

  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--palettes-red-5);
  }
}

.alertText {
  width: calc(100% - 60px);

  color: var(--palettes-red-100) !important;
}

.iconChevron {
  margin-top: 1px;
}

.card {
  position: relative;

  width: 100%;

  margin-bottom: 8px;
  padding: 8px 8px 8px 32px;
  border: 1px solid transparent;
  border-radius: 8px;

  hyphens: none;

  background-color: var(--palettes-blue-10);

  cursor: pointer;

  transition: border-color 0.3s ease-out;

  &:hover {
    border-color: var(--palettes-blue-80);
  }
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  overflow: hidden;
}

.comment {

  display: -webkit-box;
  margin: 8px 0;

  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.date {
  color: var(--palettes-gray-120)
}

.circle {
  position: absolute;
  top: 15px;
  left: 18px;

  width: 6px;
  height: 6px;
  border-radius: 50%;

  background-color: var(--palettes-blue-100);
}

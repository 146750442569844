@import 'src/styles/variables';

.wrapper {
  position: absolute;
  top: 45px;
  left: calc(-100% - 207px);
  z-index: var(--header);

  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 340px;
  max-height: 90vh;
  padding: 16px 0;

  border-radius: 16px;

  background: var(--palettes-white-100);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.10), 0 16px 32px 0 rgba(0, 0, 0, 0.10);
  visibility: hidden;

  opacity: 0;

  transition: opacity 0.3s ease-out;

  @media (max-width: $lg) {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    max-height: 100vh;

    margin-top: 20px;

    transform: translateY(100%);

    visibility: visible;
    opacity: 1;

    transition: transform 0.3s ease-out;
  }
}

.wrapperOpen {
  visibility: visible;
  opacity: 1;

  @media (max-width: $lg) {
    transform: translateY(0);
  }
}

.titleWrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 16px 16px;

  border-bottom: 1px var(--palettes-gray-20) solid;
}

.closeIcon {
  display: none;

  @media (max-width: $lg) {
    display: inline;
  }
}

.notificationsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 0 8px 55px;

  overflow: scroll;

  @media (max-width: $lg) {
    height: 100%;

    padding-bottom: 68px;
  }
}

.notNotificationsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $lg) {
    position: relative;
    top: 25%;

    justify-content: center;
  }
}

.illustration {
  margin: 25px 0 17px;
}

.notNotificationsText {
  margin-bottom: 24px;

  color: var(--palettes-gray-120);
}

.notificationCard {
  width: 100%;

  margin-bottom: 8px;
  padding: 8px 8px 8px 32px;

  border-radius: 8px;
}

.buttonWrapper {
  position: absolute;
  bottom: 3px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  height: 70px;

  border-radius: 0 0 20px 20px;

  background: var(--text-secondary);

  @media (max-width: $lg) {
    height: 80px;
  }
}

.button {
  width: 308px;

  margin: 10px 0 10px;

  @media (max-width: $lg) {
    position: fixed;
    bottom: 3%;

    width: 95%;
  }
}

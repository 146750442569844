.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  padding: 5px;
  border-radius: 21px;

  white-space: nowrap;

  background-color: var(--palettes-red-100);
}

.caption {
  color: var(--palettes-white-100);
  font-weight: 700;
}

.link {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: var(--default-header-height);
  margin-bottom: -1px;
  padding: 0 8px;

  white-space: nowrap;

  transition: background-color 0.3s ease-out;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 2px;

    background-color: var(--palettes-gray-190);
    transform: scaleX(0);

    transition: transform 0.2s ease-out;

    content: '';
  }

  &:hover {
    background-color: var(--palettes-gray-5);

    &::after {
      transform: scale(1);
    }
  }

  &:active {
    background-color: var(--palettes-gray-10);
  }
}
